var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.fieldsLoading
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            _vm._l(12, function (n) {
              return _c(
                "v-col",
                { key: n, attrs: { cols: "4" } },
                [
                  _c(
                    "v-sheet",
                    { staticClass: "my-2 px-2" },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "mx-auto",
                        attrs: { type: "image", height: "75" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _c(
            "div",
            { staticClass: "mt-1" },
            [
              !_vm.activeHtmlLink
                ? _c(
                    "v-card",
                    { staticClass: "pa-0", attrs: { flat: "", tile: "" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "mx-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-1",
                              attrs: { cols: "12", sm: "12", md: "6", lg: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "append-icon": "mdi-magnify",
                                  label: _vm.$t("message.common.search"),
                                  "hide-details": "",
                                  "single-line": "",
                                  outlined: "",
                                  dense: "",
                                },
                                on: {
                                  input: _vm.debouncedSearchPublishedFiles,
                                },
                                model: {
                                  value: _vm.fileSearchTerm,
                                  callback: function ($$v) {
                                    _vm.fileSearchTerm = $$v
                                  },
                                  expression: "fileSearchTerm",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.breadCrumbItems.length > 1 && !_vm.fileSearchTerm
                            ? _c("v-breadcrumbs", {
                                staticClass: "pa-0 px-3 custom_breadcrumb",
                                attrs: { items: _vm.breadCrumbItems },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function (ref) {
                                        var item = ref.item
                                        return [
                                          _c("v-hover", {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    return [
                                                      _c(
                                                        "v-breadcrumbs-item",
                                                        {
                                                          class:
                                                            _vm.getBreadCrumbItemIndex(
                                                              item
                                                            ) !==
                                                            _vm.breadCrumbItems
                                                              .length -
                                                              1
                                                              ? "pointer-hand custom_breadcrumb_item_style"
                                                              : "",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.getBreadCrumbItemIndex(
                                                                item
                                                              ) !==
                                                              _vm
                                                                .breadCrumbItems
                                                                .length -
                                                                1
                                                                ? _vm.navigateToItem(
                                                                    item
                                                                  )
                                                                : null
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm.getBreadCrumbItemIndex(
                                                            item
                                                          ) ===
                                                          _vm.breadCrumbItems
                                                            .length -
                                                            1
                                                            ? _c("span", [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.text ||
                                                                        item.original_name ||
                                                                        ""
                                                                    )
                                                                  ),
                                                                ]),
                                                              ])
                                                            : _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.text ||
                                                                      item.original_name ||
                                                                      ""
                                                                  )
                                                                ),
                                                              ]),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ]
                                      },
                                    },
                                    {
                                      key: "divider",
                                      fn: function () {
                                        return [
                                          _c("v-icon", [
                                            _vm._v("mdi-chevron-right"),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2418314511
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("v-progress-linear", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loading || _vm.searchLoading,
                            expression: "loading || searchLoading",
                          },
                        ],
                        attrs: { indeterminate: "" },
                      }),
                      (
                        _vm.fileSearchTerm
                          ? _vm.listOfSearchResult.length > 0
                          : _vm.listOfDocuments.length > 0
                      )
                        ? _c(
                            "v-row",
                            { staticClass: "mx-0" },
                            [
                              _vm._l(
                                _vm.fileSearchTerm
                                  ? _vm.listOfSearchResult
                                  : _vm.listOfDocuments,
                                function (document) {
                                  return [
                                    document.id
                                      ? _c(
                                          "v-col",
                                          {
                                            key: document.id + "_document",
                                            staticClass: "pa-0",
                                            attrs: { cols: "3" },
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass: "pa-0 ma-1",
                                                attrs: { tile: "", flat: "" },
                                                on: {
                                                  click: function ($event) {
                                                    document.is_dir
                                                      ? _vm.getChildren(
                                                          document
                                                        )
                                                      : _vm.loadHtmlFile(
                                                          document
                                                        )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-center pt-1",
                                                  },
                                                  [
                                                    _c(
                                                      "v-avatar",
                                                      {
                                                        attrs: { slot: "48" },
                                                        slot: "48",
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color:
                                                                document.is_dir
                                                                  ? "#f6b21b"
                                                                  : "",
                                                              size: "48",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                document.is_dir
                                                                  ? "mdi-folder"
                                                                  : "mdi-file-document"
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-card-text",
                                                  {
                                                    staticClass:
                                                      "text-center pa-0 font-weight-medium",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        document.text ||
                                                          document.original_name ||
                                                          ""
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                }
                              ),
                            ],
                            2
                          )
                        : !_vm.loading || !_vm.searchLoading
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "mt-2 mx-1",
                              attrs: { prominent: "", text: "", type: "info" },
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-col", { staticClass: "grow" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("message.common.noResults"))
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "mx-0" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pa-1", attrs: { cols: "10" } },
                            [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.$t("message.common.filename")) +
                                    ":"
                                ),
                              ]),
                              _c("br"),
                              _vm._v(" " + _vm._s(_vm.activeHtmlTitle)),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-1 text-right",
                              attrs: { cols: "2" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    small: "",
                                    color: "secondary",
                                    icon: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.activeHtmlLink = ""
                                      _vm.activeHtmlTitle = ""
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        { staticClass: "mx-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-1 text-left",
                              attrs: { cols: "2" },
                            },
                            [
                              _vm.previous_document
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        large: "",
                                        color: "secondary",
                                        icon: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.loadHtmlFile(
                                            _vm.previous_document
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-chevron-left"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("v-col", { attrs: { cols: "8" } }),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-1 text-right",
                              attrs: { cols: "2" },
                            },
                            [
                              _vm.next_document
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        large: "",
                                        color: "secondary",
                                        icon: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.loadHtmlFile(
                                            _vm.next_document
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-chevron-right"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "main_pdf_view" },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-0",
                              style: { minWidth: _vm.widthPdf + "px" },
                            },
                            [
                              _vm.iframeSrcLoading && !_vm.showPdfPreviewDialog
                                ? _c("v-progress-linear", {
                                    attrs: {
                                      indeterminate: "",
                                      color: "primary",
                                    },
                                  })
                                : _vm._e(),
                              _vm.showPdfPreviewDialog
                                ? _c(
                                    "v-responsive",
                                    { attrs: { "aspect-ratio": 16 / 9 } },
                                    [
                                      _c("pdf-viewer", {
                                        attrs: { fileUrl: _vm.activeHtmlLink },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-responsive",
                                    {
                                      style:
                                        "visibility: " +
                                        (_vm.iframeSrcLoading ? "hidden" : ""),
                                      attrs: { "aspect-ratio": 16 / 9 },
                                    },
                                    [
                                      _c("iframe", {
                                        staticClass: "files-html",
                                        attrs: {
                                          width: "100%",
                                          src: _vm.activeHtmlLink,
                                        },
                                        on: {
                                          load: function ($event) {
                                            _vm.iframeSrcLoading = false
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "button__box" }, [
                        _c(
                          "button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.pluspdfwidth(_vm.widthPdf)
                              },
                            },
                          },
                          [_vm._v("+")]
                        ),
                        _c(
                          "button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.minspdfwidth(_vm.widthPdf)
                              },
                            },
                          },
                          [_vm._v("-")]
                        ),
                      ]),
                    ],
                    1
                  ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }